
import Layout from "@/views/routerViews/emptyRouterViews.vue";
import Login from "@/views/login/index.vue";
const routes = [
  {
    path: "/",
    name: "root",
    // component: Layout,
    redirect: { path:'login' },
    meta: {
      title: "玲珑登录页",
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/login/index.vue"),
        meta: {
          title: "玲珑登录页",
          noCache: true
        },
      },
      {
        path: "live",
        name: "Live",
        component: () => import("@/views/live/index.vue"),
        meta: {
          title: "直播页面",
          noCache: true
        },
      },
    ],
  }
];

export default routes;
