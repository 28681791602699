import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import routes from "@/router/router";
import { createRouter, createWebHashHistory } from "vue-router";

import { store } from "./store";
import 'element-plus/dist/index.css';
import { tokenStore } from './store/authtoken'
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
});
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 设置页面缩放比例为1.0
function setZoomLevel(scale = 1.0) {
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'viewport');
  meta.setAttribute('content', `width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`);
  document.getElementsByTagName('head')[0].appendChild(meta);

}

// 在Vue实例化之前调用
setZoomLevel(1.0);


// 5. 创建并挂载根实例
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

//确保 _use_ 路由实例使
//整个应用支持路由。
app.use(router);
app.use(store);

// 全局注册icon（后期可优化，按需注册）
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component);
// }

app.mount("#app");
import {tokenStoreHook} from '@/store/authtoken';
// 在应用挂载后恢复数据
tokenStoreHook().loadData();
// 白名单数组
const whiteList = ['/', '/login']
// 路由拦截
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    // 如果在白名单内，允许访问
    next()
  } else {
    if (!tokenStoreHook().token) {
      next('/login'); // 未认证，重定向到登录页
    } else {
      next(); // 继续
    }
  }

});
