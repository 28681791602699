import { defineStore } from "pinia";
import { store } from "@/store";

export const tokenStore = defineStore({
    id: "cached-token",
    state: () => ({
        // 缓存系统token
        token:'',
        userId:'',
        roomId:'',
        name:'',
    }),
    actions: {
        addToken(token,uid,rid,name) {
            //不管token有没有值都覆盖当前的缓存
            this.token=token;
            this.userId=uid;
            this.roomId=rid;
            this.name=name;
            this.saveData(); //持久化
        },
        delToken() {
            this.token='';
            this.userId='';
            this.roomId='';
            this.name='';
            localStorage.removeItem('basicInfo')
        },
        saveData() {
            localStorage.setItem('basicInfo', JSON.stringify(
              {token:this.token,userId:this.userId,roomId:this.roomId,name:this.name}
            ));
        },
        loadData() {
            const savedData = localStorage.getItem('basicInfo');
            if (savedData) {
                let obj= JSON.parse(savedData);
                this.token=obj.token;
                this.userId=obj.userId;
                this.roomId=obj.roomId;
                this.name=obj.name;
            }
        }
    }
});

export function tokenStoreHook() {
    return tokenStore(store);
}
