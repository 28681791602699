import Axios from "axios";
// import NProgress from "../progress";
// import { showFailToast } from "vant";
// import "vant/es/toast/style";
import { ElMessage ,ElMessageBox} from 'element-plus';

import { tokenStoreHook } from "@/store/authtoken";
import CryptoJS from 'crypto-js'
// 默认 axios 实例请求配置
const configDefault = {
  headers: {
     //"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      "Content-Type": "application/json"
  },
  baseURL: import.meta.env.VITE_BASE_API,
  dataType: "json",
  data: {}
};
function isFormData(obj) {
  return obj instanceof FormData;
}
// java服务
const configJavaDefault = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  },
  timeout: 0,
  baseURL: import.meta.env.VITE_BASE_JAVA_API,
  data: {},

};

class Http {
  constructor(config) {
    Http.axiosConfigDefault = config;
    Http.axiosInstance = Axios.create(config);
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }
  // 当前实例
  static axiosInstance;
  // 请求配置
  static axiosConfigDefault;

  // 请求拦截
  httpInterceptorsRequest() {
    Http.axiosInstance.interceptors.request.use(
      config => {
        //NProgress.start();
        // 发送请求前，可在此携带 token
        if (tokenStoreHook().token) {
          config.headers['authorization'] = tokenStoreHook().token
        }
        return config;
      },
      error => {
        ElMessage(error.message);
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  httpInterceptorsResponse() {
    Http.axiosInstance.interceptors.response.use(
      response => {
       // NProgress.done();
        // 与后端协定的返回字段
        const { code, message, data } = response.data;
        // 判断请求是否成功 （code 200 请求成功）
        const isSuccess =
            data && Reflect.has(response.data, "code") && code === 200;
        if (isSuccess) {
          return response.data;
        } else {
          if(response.data.code==401){
            ElMessageBox.confirm('当前账户登录已过期，或在其它设备打开，请重新登陆', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
              tokenStoreHook().delToken();
              location.href = '/login';
            }).catch(() => {});
          }else{
            return response.data;
          }


          // 处理请求错误
          // showFailToast(message);
          //console.log(message);
         // return Promise.reject(response.data);
        }
      },
      error => {
       // NProgress.done();
        // 处理 HTTP 网络错误
        let message = "";
        // HTTP 状态码
        const status = error.response?.status;
        switch (status) {
          case 400:
            message = "请求错误";
            break;
          case 401:
            message = "未授权，请登录";
            break;
          case 403:
            message = "拒绝访问";
            break;
          case 404:
            message = `请求地址出错: ${error.response?.config?.url}`;
            break;
          case 408:
            message = "请求超时";
            break;
          case 500:
            message = "服务器内部错误";
            break;
          case 501:
            message = "服务未实现";
            break;
          case 502:
            message = "网关错误";
            break;
          case 503:
            message = "服务不可用";
            break;
          case 504:
            message = "网关超时";
            break;
          case 505:
            message = "HTTP版本不受支持";
            break;
          default:
            message = "网络连接故障";
        }

        ElMessage(message);
        return Promise.reject(error);
      }
    );
  }


  // 通用请求函数ß
  request(paramConfig) {
    //paramConfig.params=Qs.stringify( paramConfig.params);
    var cfg= { ...Http.axiosConfigDefault, ...paramConfig };
    // if(paramConfig.url.indexOf('admin')!=-1){
    //   Http.axiosConfigDefault.baseURL='/admin';
    // }else{
    //   Http.axiosConfigDefault.baseURL='/api';
    // }
    //Http.axiosConfigDefault.baseURL=import.meta.env.VITE_BASE_API;

    const config = { ...Http.axiosConfigDefault, ...paramConfig };
    //post数据做签名和时间戳
    if(!config.data){
      config.data={};
    }
    if(isFormData(config.data)){

    }else{
      var jsonObj =JSON.parse(JSON.stringify( config.data));//
      var timeSpan = new Date().getTime();
      jsonObj.timestamp = timeSpan
      var sortObj = Object.keys(jsonObj).sort();

      var sortJson = {};
      sortObj.forEach((key) => {
        sortJson[key] = jsonObj[key]; // 按排序后的键顺序构造新的对象
      });
      var jsonStr = '';
      // if(Array.isArray(sortJson) ){
      //
      // }else{

        for (let key in sortJson) {
          if (sortJson.hasOwnProperty(key)) {
            jsonStr += (key + sortJson[key])
          }
        }
      //}

      var skey = 'HT*l$BCvf3tPOmH8^K11x@4mr7uroL';
      jsonStr = skey + jsonStr + skey + timeSpan;
      jsonObj.sign = CryptoJS.SHA256(jsonStr).toString();
      jsonObj.timestamp = timeSpan;
      config.data=jsonObj
    }
    return new Promise((resolve, reject) => {
      Http.axiosInstance
        .request(config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export const http = new Http(configDefault);
